import _ from "lodash"
import React from "react"
import styled, { keyframes } from "styled-components"
import Genius from "../images/GeniusSports.svg"
import GlobalStyle from "../components/globalStyles"
import below from "../styles/media"

const BackgroundChange = keyframes`
  from {
    background: white;
    color: #0000dc;
  }
  to {
    background: #0000dc;
    color: white;
  }
`

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 150px;
  ${below.phone`
    padding-top: 20px;
  `}
`

const InnerCard = styled.div`
  display: flex;
  width: 80%;
  height: 80%;
  background: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  ${below.phone`
    flex-direction: column;
    width: 95%;
    height: 95%;
  `}
`

const InnerLeft = styled.div`
  width: 66.66%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 30px;
  text-align: left;
  padding-left: 100px;
  border-radius: 15px 0px 0px 15px;
  h1 {
    margin-bottom: 20px;
    font-family: "KlarheitKurrentRegular";
    font-size: 90px;
    color: #0000dc;
  }
  p {
    color: #000;
    font-size: 24px;
    font-family: "RedHatTextRegular";
    padding-right: 20%;
    strong {
      font-family: "RedHatTextMedium";
    }
  }
  a {
    font-family: "KlarheitKurrentRegular";
    font-size: 24px;
    height: 62px;
    color: #0000dc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 240px;
    border: 2px solid #0000dc;
    border-radius: 29px;
    padding-bottom: 2px;
    text-decoration: none;
    margin-top: 20px;
    &:hover {
      animation: ${BackgroundChange} 0.3s linear;
      background: #0000dc;
      color: white;
    }
  }
  ${below.phone`
    width: 100%;
    padding: 10px;
    align-items: center;
    border-radius: 15px 15px 0px 0px;
    h1 {
      font-family: "KlarheitKurrentRegular";
      font-size: 24px;
      text-align: center;
    }
    p {
      font-size: 16px;
      padding-right: 0;
      text-align: center;
    }
    a{
      width: 200px;
      height: 50px;
      font-size: 14px;
    }
  `}
`

const InnerRight = styled.div`
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 0px 15px 15px 0;
  }
  ${below.phone`
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      border-radius: 0px 0 15px 15px;
    }
  `}
`

const GeniusHoldingPage = props => {
  return (
    <PageWrapper>
      <GlobalStyle includeFontFamily />
      <InnerCard>
        <InnerLeft className="inner-left">
          <h1>
            Looking <br /> for Fanhub?
          </h1>
          <p>
            Our game-changing fan engagement and <br />
            <strong>Free-to-Play solutions</strong> can now be found at Genius
            Sports, which FanHub became a part of in May 2022
          </p>
          <a href="https://geniussports.com/media/" target="_blank">
            Our new home
          </a>
        </InnerLeft>
        <InnerRight>
          <img src={Genius} alt="Fanhub" />
        </InnerRight>
      </InnerCard>
    </PageWrapper>
  )
}

export default GeniusHoldingPage
