import { graphql, navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import Button from "../components/Button"
import { getIcon } from "../components/Icons"
import PageLayout, { PageContentsWrapper } from "../components/PageLayout"
import Spacer from "../components/Spacer"
import GeniusHoldingPage from './geniusHolding';
import {
	createStaticContent,
	getPageTitle,
	transformToArray,
} from "../data/transform"
import colors from "../styles/colors"
import below from "../styles/media"

const PageTitleBlock = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 538px;
  width: 655px;

  margin: 80px auto;

  color: ${colors.blue};

  h1 {
    font-size: 200px;
    font-weight: 600;
    margin: 0;
  }
  h2 {
    font-size: 43px;
    font-weight: 600;
    margin: 0 0 30px;
  }

  > * {
    z-index: 1;
  }

  > svg {
    width: 400px;
    z-index: 0;
  }

  > svg:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
  }
  > svg:last-of-type {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  ${below.tablet`
    height: 80vw;
    width: 100%;
    h1 {
      font-size: 110px;
    }
    h2 {
      font-size: 32px;
    }
    > svg {
      width: 60vw;
    }
  `}
`

const NotFoundPage = props => {
	const locations = transformToArray(
		props.data,
		"locations",
		record => record.inFooter
	)
	const content = createStaticContent(props.data, "404")
	return (
		<GeniusHoldingPage />
	)
}

export const query = graphql`
  query NotFoundQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
  }
`

export default NotFoundPage
